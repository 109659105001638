import { useState, useEffect } from 'react';
import Prismic from '@prismicio/client';
import { client } from '../prismic-configuration';

export const usePrismicArticle = (uid) => {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!uid) {
      setError(new Error('UID is not provided'));
      setLoading(false);
      return;
    }

    const fetchArticle = async () => {
      try {
        const response = await client.getByUID('article', uid);
        setArticle(response);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [uid]);

  return { article, loading, error };
};
