import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { usePrismicArticle } from '../hooks/usePrismicArticle';
import { PrismicRichText } from '@prismicio/react';
import {
  ArticleContainer,
  ArticleHeader,
  ArticleTitle,
  ArticleContent,
  BackIcon,
  BackIconContainer,
  LoadingMessage,
  ErrorMessage
} from './ArticleStyles';
import { ReactComponent as ArrowLeftIcon } from './assets/arrow-left-icon.svg';

function Article() {
  const { uid } = useParams();
  const { article, loading, error } = usePrismicArticle(uid);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://dapper-faloodeh-0dad9e.netlify.app/widget.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  if (loading) return <LoadingMessage>Chargement de l'article...</LoadingMessage>;
  if (error) return <ErrorMessage>Erreur lors du chargement de l'article: {error.message}</ErrorMessage>;

  return (
    <ArticleContainer>
      <BackIconContainer>
        <Link to="/">
          <BackIcon as={ArrowLeftIcon} />
        </Link>
      </BackIconContainer>
      {article ? (
        <>
          <ArticleHeader>
            <ArticleTitle>
              {article.data.title ? (
                <PrismicRichText field={article.data.title} />
              ) : (
                "Titre non disponible"
              )}
            </ArticleTitle>
          </ArticleHeader>
          <ArticleContent>
            <PrismicRichText field={article.data.content} />
          </ArticleContent>
        </>
      ) : (
        <p>Article non trouvé.</p>
      )}
    </ArticleContainer>
  );
}

export default Article;
