import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HomeContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`;

export const Header = styled.header`
  text-align: center;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

export const Logo = styled.h1`
  font-size: 48px;
  font-weight: 300;
  letter-spacing: 8px;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  display: inline-block;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background-color: #333;
    transition: all 0.5s ease;
  }

  &:hover::after {
    left: 0;
  }

  @media (max-width: 768px) {
    font-size: 36px;
    letter-spacing: 6px;
  }
`;

export const SocialIcons = styled.div`
  margin-top: 20px;
`;

export const SocialIcon = styled.a`
  margin: 0 10px;
  color: #333;
  font-size: 24px;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #888;
  }
`;

export const ArticleList = styled.ul`
  list-style-type: none;
  padding: 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #ddd;
  }

  @media (max-width: 768px) {
    &::before {
      left: 15px;
    }
  }
`;

export const ArticleItem = styled.li`
  margin-bottom: 40px;
  padding-left: 30px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -4px;
    top: 8px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #333;
    transition: background-color 0.3s ease;
  }

  &:hover::before {
    background-color: #888;
  }

  @media (max-width: 768px) {
    padding-left: 45px;

    &::before {
      left: 11px;
    }
  }
`;

export const ArticleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
  &:hover {
    color: #888;
  }
`;

export const ArticleTitle = styled.h2`
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const ArticleDate = styled.span`
  font-size: 14px;
  color: #888;
  font-style: italic;
  position: absolute;
  left: -140px;
  top: 5px;
  width: 120px;
  text-align: right;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    width: 10px;
    height: 1px;
    background-color: #ddd;
  }

  @media (max-width: 768px) {
    position: static;
    display: block;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 10px;

    &::after {
      display: none;
    }
  }
`;

export const LoadingMessage = styled.div`
  font-size: 18px;
  color: #888;
  text-align: center;
`;

export const ErrorMessage = styled.div`
  font-size: 18px;
  color: #e74c3c;
  text-align: center;
`;