import React from 'react'
import { usePrismicArticles } from '../hooks/usePrismicArticles'
import { PrismicRichText } from '@prismicio/react'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import {
  HomeContainer,
  Header,
  Logo,
  SocialIcons,
  SocialIcon,
  ArticleList,
  ArticleItem,
  ArticleLink,
  ArticleTitle,
  ArticleDate,
  LoadingMessage,
  ErrorMessage
} from './HomeStyles'

function Home() {
  const { articles, loading, error } = usePrismicArticles()

  if (loading) return <LoadingMessage>Chargement des articles...</LoadingMessage>
  if (error) return <ErrorMessage>Erreur lors du chargement des articles: {error.toString()}</ErrorMessage>

  // Sort articles by date (newest first)
  const sortedArticles = articles.sort((a, b) => {
    return new Date(b.data.date) - new Date(a.data.date)
  })

  return (
    <HomeContainer>
      <Header>
        <Logo>Ai.Khedji</Logo>
        <SocialIcons>
          <SocialIcon href="https://x.com/AI_Khedji" target="_blank" rel="noopener noreferrer">𝕏</SocialIcon>
          <SocialIcon href="https://www.linkedin.com/in/ali-khedji-769784a4/" target="_blank" rel="noopener noreferrer">in</SocialIcon>
        </SocialIcons>
      </Header>
      {sortedArticles.length > 0 ? (
        <ArticleList>
          {sortedArticles.map((article) => (
            <ArticleItem key={article.id}>
              <ArticleLink to={`/article/${article.uid}`}>
                <ArticleTitle>
                  {article.data.title ? (
                    <PrismicRichText field={article.data.title} />
                  ) : (
                    "Titre non disponible"
                  )}
                </ArticleTitle>
                <ArticleDate>
                {format(new Date(article.data.date), 'd MMMM yyyy', { locale: fr })}
                </ArticleDate>
              </ArticleLink>
            </ArticleItem>
          ))}
        </ArticleList>
      ) : (
        <p>Aucun article trouvé.</p>
      )}
    </HomeContainer>
  )
}

export default Home