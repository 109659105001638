import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ArticleContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`;

export const ArticleHeader = styled.header`
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const ArticleTitle = styled.h1`
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 4px;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  display: inline-block;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background-color: #333;
    transition: all 0.5s ease;
  }

  &:hover::after {
    left: 0;
  }

  @media (max-width: 768px) {
    font-size: 28px;
    letter-spacing: 3px;
  }
`;

export const ArticleContent = styled.div`
  font-size: 18px;
  line-height: 1.8;
  color: #555;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  p {
    margin-bottom: 20px;
  }

  h2 {
    font-size: 24px;
    font-weight: 300;
    margin-top: 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 20px;
      margin-top: 30px;
      margin-bottom: 15px;
    }
  }

  ul, ol {
    margin-bottom: 20px;
    padding-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 30px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.02);
    }

    @media (max-width: 768px) {
      margin: 20px auto;
    }
  }
`;

export const BackIconContainer = styled.div`
  text-align: left;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const BackIcon = styled.svg`
  width: 24px;
  height: 24px;
  fill: #333;
  transition: fill 0.3s ease;

  &:hover {
    fill: #888;
  }
`;

export const LoadingMessage = styled.div`
  font-size: 18px;
  color: #888;
  text-align: center;
`;

export const ErrorMessage = styled.div`
  font-size: 18px;
  color: #e74c3c;
  text-align: center;
`;
