import { useState, useEffect } from 'react'
import { client } from '../prismic'

export function usePrismicArticles() {
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchArticles = async () => {
      console.log("Début de fetchArticles");
      try {
        console.log("Client Prismic:", client);
        console.log("Tentative de récupération des articles...");
        const response = await client.getAllByType('article');
        console.log('Réponse de Prismic:', response)
        setArticles(response)
        setLoading(false)
      } catch (err) {
        console.error('Erreur lors de la récupération des articles:', err)
        setError(err)
        setLoading(false)
      }
      console.log("Fin de fetchArticles");
    }

    fetchArticles()
  }, [])

  return { articles, loading, error }
}